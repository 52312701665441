$firstAnimationDuration: 800ms;
$secondAnimationDuration: 300ms;

.page-content {
    animation-name: pageContentAnimation;
    animation-duration: $firstAnimationDuration;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: both;

    @keyframes pageContentAnimation {
        0% {
            padding-top: 100px;
            opacity: 0;
        }

        100% {
            padding-top: 0;
            opacity: 1;
        }
    }
}

.page-text {
    animation-name: pageTextAnimation;
    animation-duration: $firstAnimationDuration;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: both;

    @keyframes pageTextAnimation {
        0% {
            padding-top: 50px;
        }

        100% {
            padding-top: 0;
        }
    }

    p {
        animation-name: pageTextParagraphAnimation;
        animation-duration: $firstAnimationDuration;
        animation-timing-function: ease-in-out;
        animation-iteration-count: 1;
        animation-fill-mode: both;

        @keyframes pageTextParagraphAnimation {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }
    }
}

.site-header {
    animation-delay: $firstAnimationDuration;
    animation-name: siteHeaderAnimation;
    animation-duration: $secondAnimationDuration;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: both;

    @keyframes siteHeaderAnimation {
        0% {
            margin-top: -20px;
            opacity: 0;
        }

        100% {
            margin-top: 0;
            opacity: 1;
        }
    }
}

.site-footer {
    animation-delay: $firstAnimationDuration;
    animation-name: siteFooterAnimation;
    animation-duration: $secondAnimationDuration;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: both;

    @keyframes siteFooterAnimation {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
}
