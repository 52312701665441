@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 800px, // updated
  lg: 821px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px
);

// spacers
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),    //4px
    2: ($spacer * .5),     //8px
    3: $spacer,            //16px
    4: ($spacer * 1.5),    //24px
    5: ($spacer * 3),      //48px
    6: ($spacer * 4),      //64px
    7: ($spacer * 5),      //80px
    8: ($spacer * 6.25),   //100px
    9: ($spacer * 7.5),    //120px
    10: ($spacer * 9.375)  //150px
  ),
  $spacers
);

// gutter
$grid-gutter-width: 2.5rem;
$gutters: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);

:root {
  --bs-gutter-x: 1.25rem;

  @media (min-width: map-get($container-max-widths, "lg")) {
    --bs-gutter-x: 3.125rem;
  }
}

.container {
  max-width: 100% !important;
}

.content-area {
  max-width: calc(min(100%, 1920px)) !important;
  margin: 0 auto;
}

@import "~bootstrap";